import React, { useState } from 'react'
import {
  Button,
  Columns,
  FormLayout,
  Inline,
  Label,
  Modal,
} from '@therms/atalaya'
import { TaskScheduleFormFields } from '@src/modules/Tasks/components/TaskScheduleFormFields'
import { RRule } from 'rrule'
import { RRuleText } from '@src/modules/Tasks/components/RRuleText'
import { FieldArray, useFormikContext } from 'formik'
import { FormikCheckbox } from '@src/components/FormikFields/FormikCheckbox'

function TaskSchedule() {
  const form = useFormikContext<any>()
  const [showTaskScheduleModalIndex, setShowTaskScheduleModalIndex] = useState<
    number | undefined
  >(undefined)

  return (
    <FieldArray
      name="scheduleStrings"
      render={(arrayHelpers) => (
        <FormLayout.Section
          heading="Schedule"
          subTitle="Configure how often & when this Task should be completed"
        >
          <FormLayout dividers="lines">
            <Columns space="base">
              <Columns.Column>
                <div className="flex ">
                  <FormikCheckbox
                    label="Allow this task to be completed at any time"
                    name="isAnyTime"
                  />
                </div>
              </Columns.Column>
              <Columns.Column>
                <div className="flex ">
                  <FormikCheckbox
                    label="Allow this task to be completed up to 24 hours in advance"
                    name="advancedViewing"
                  />
                </div>
              </Columns.Column>
            </Columns>

            {form.values.scheduleStrings?.map((taskSchedule, i) => (
              <div>
                {i > 0 && <FormLayout.Divider />}
                <Inline alignX="between" width="full">
                  <div className="flex-wrap">
                    <Label>#{i + 1}</Label>{' '}
                    <span>
                      <RRuleText rruleString={taskSchedule} />
                    </span>
                  </div>

                  <Inline space="sm">
                    <Button
                      onClick={() => setShowTaskScheduleModalIndex(i)}
                      subtle
                    >
                      Edit
                    </Button>

                    <Button
                      onClick={() => {
                        arrayHelpers.remove(i)
                      }}
                      subtle
                      variant="critical"
                    >
                      Remove
                    </Button>
                  </Inline>
                </Inline>
              </div>
            ))}
          </FormLayout>

          <Button
            fullWidth
            onClick={() => {
              arrayHelpers.push(
                new RRule({
                  byhour: [0],
                  byminute: [0],
                  bysecond: [0],
                  freq: RRule.DAILY,
                  interval: 1,
                }).toString(),
              )

              setShowTaskScheduleModalIndex(
                form.values.scheduleStrings.length + 1,
              )
            }}
            subtle
          >
            + Add Schedule
          </Button>

          {showTaskScheduleModalIndex !== undefined &&
            form.values.scheduleStrings[showTaskScheduleModalIndex] && (
              <Modal
                closeHandler={() => setShowTaskScheduleModalIndex(undefined)}
                escPressClose
                header={`Edit Task Schedule #${showTaskScheduleModalIndex + 1}`}
              >
                <FormLayout
                  heading={
                    <span className="text-color-info flex">
                      <RRuleText
                        rruleString={
                          form.values.scheduleStrings[
                            showTaskScheduleModalIndex
                          ]
                        }
                      />
                    </span>
                  }
                >
                  <TaskScheduleFormFields
                    initialScheduleStringValue={
                      form.values.scheduleStrings[showTaskScheduleModalIndex]
                    }
                    onChange={(scheduleString) => {
                      arrayHelpers.replace(
                        showTaskScheduleModalIndex,
                        scheduleString,
                      )
                    }}
                  />
                </FormLayout>
              </Modal>
            )}
        </FormLayout.Section>
      )}
    />
  )
}

TaskSchedule.defaultProps = {}

export { TaskSchedule }
