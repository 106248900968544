import React, {useState} from 'react'
import axios from 'axios'
import {Button, Image, Inline, Loading, Password, Stack,} from '@therms/atalaya'
import {useParams} from 'react-router-dom'
import {isProd} from '@src/services/api-client-instance'
import logo from '@src/assets/logo.png'
import {Alerts} from '@src/components/Alerts'

function SetPassword({ token }: { token: string }) {
  const [password, setPassword] = useState()
  const [loading, setLoading] = useState(false)

  const params = useParams<{ token: string }>()

  const handleSetPassword = async () => {
    setLoading(true)
    try {
      const api = axios.create({
        baseURL: isProd ? '/api' : 'http://localhost:3000/api',
      })

      await api.post(`/confirmPasswordReset`, {
        password,
        token: token || params.token,
      })

      console.log('SetPassword success')

      alert('Password set success - please login')

      window.location.href = '/'
    } catch (err) {
      console.log('SetPassword err', err)

      Alerts.error('Unable to set password try again')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex justify-center">
      {loading && <Loading overlay />}

      <div className="w-full md:w-72">
        <Inline className="mb-xl" alignX="center" width="full">
          <Image src={logo} width={200} />
        </Inline>
        <Stack alignX="stretch" space="sm" width="full">
          <Password
            label="New Password"
            onChangeValue={setPassword}
            value={password}
          />

          <Button onClick={handleSetPassword} fullWidth variant="main">
            Set Password
          </Button>
        </Stack>
      </div>
    </div>
  )
}

SetPassword.defaultProps = {}

export { SetPassword }
