import { useEffect, useState } from 'react'
import {
  getSelf,
  SelfResponse,
} from '@src/generatedClient/generated/taskreportsApi'

interface UseSelfReturn {
  self: SelfResponse | null
  loading: boolean
  error: Error | null
}

export const useSelf = (): UseSelfReturn => {
  const [data, setData] = useState<SelfResponse | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const fetchSelf = async () => {
      try {
        setLoading(true)
        const response = await getSelf()
        setData(response.data)
        setError(null)
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error('Failed to fetch user data'),
        )
        setData(null)
      } finally {
        setLoading(false)
      }
    }

    fetchSelf()
  }, [])

  return { self: data, loading, error }
}
