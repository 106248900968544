import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {SITES_ROUTES} from '../constants'
import {SiteForm} from '@src/modules/Sites/router/screens/SiteForm'
import {ViewSite} from '@src/modules/Sites/router/screens/ViewSite'
import {SitesIndex} from '@src/modules/Sites/router/screens/Index'
import {ErrorBoundary} from 'react-error-boundary'
import {Button} from '@therms/atalaya'
import * as Sentry from '@sentry/react'
import {ViewSiteStats} from '@src/modules/Sites/router/screens/ViewSiteStats'
import {TemplateForm} from "@src/modules/Sites/router/screens/TemplateForm"


function SitesRoutes() {
  return (
    <Switch>
      <Route
        render={() => (
          <ErrorBoundary
            onError={(error, componentStack) => {
              Sentry.withScope((scope) => {
                scope.setExtras(componentStack)
                Sentry.captureException(error)
              })
            }}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <Button onClick={resetErrorBoundary}>Try again</Button>
              </div>
            )}
          >
            <SiteForm />
          </ErrorBoundary>
        )}
        path={SITES_ROUTES.EDIT_SITE}
      />

    <Route
        render={() => (
            <ErrorBoundary
                onError={(error, componentStack) => {
                    Sentry.withScope((scope) => {
                        scope.setExtras(componentStack)
                        Sentry.captureException(error)
                    })
                }}
                fallbackRender={({ error, resetErrorBoundary }) => (
                    <div>
                        <p>Something went wrong:</p>
                        <pre>{error.message}</pre>
                        <Button onClick={resetErrorBoundary}>Try again</Button>
                    </div>
                )}
            >
                <TemplateForm />
            </ErrorBoundary>
        )}
        path={SITES_ROUTES.SITE_TEMPLATES}
    />

      <Route
        render={() => (
          <ErrorBoundary
            onError={(error, componentStack) => {
              Sentry.withScope((scope) => {
                scope.setExtras(componentStack)
                Sentry.captureException(error)
              })
            }}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <Button onClick={resetErrorBoundary}>Try again</Button>
              </div>
            )}
          >
            <SiteForm />
          </ErrorBoundary>
        )}
        path={SITES_ROUTES.NEW_SITE}
      />
      <Route
        render={() => (
          <ErrorBoundary
            onError={(error, componentStack) => {
              Sentry.withScope((scope) => {
                scope.setExtras(componentStack)
                Sentry.captureException(error)
              })
            }}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <Button onClick={resetErrorBoundary}>Try again</Button>
              </div>
            )}
          >
            <ViewSite />
          </ErrorBoundary>
        )}
        path={SITES_ROUTES.VIEW_SITE}
      />

      <Route
        render={({ match }) => (
          <ErrorBoundary
            onError={(error, componentStack) => {
              Sentry.withScope((scope) => {
                scope.setExtras(componentStack)
                Sentry.captureException(error)
              })
            }}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <Button onClick={resetErrorBoundary}>Try again</Button>
              </div>
            )}
          >
              {match.params.siteId ? <ViewSiteStats siteId={match.params.siteId} /> : null}
          </ErrorBoundary>
        )}
        path={SITES_ROUTES.VIEW_SITE_STATS}
      />

      <Route
        render={() => (
          <ErrorBoundary
            onError={(error, componentStack) => {
              Sentry.withScope((scope) => {
                scope.setExtras(componentStack)
                Sentry.captureException(error)
              })
            }}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <Button onClick={resetErrorBoundary}>Try again</Button>
              </div>
            )}
          >
            <SitesIndex />
          </ErrorBoundary>
        )}
      />
    </Switch>
  )
}

export { SitesRoutes }
