import React from 'react'
import { Button, Inline, Loading } from '@therms/atalaya'
import { useHistory, useParams } from 'react-router-dom'
import { SiteGetSite } from '@src/services/api-client'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'

import { useSelf } from '@src/hooks/useSelf'
import { TemplateImport } from '@src/modules/Sites/router/screens/TemplateForm/components/TemplateImport'
import { TemplateStatusMod } from '@src/modules/Sites/router/screens/TemplateForm/components/TemplateStatusMod'

function TemplateForm() {
  const { goBack } = useHistory()
  const { siteId } = useParams<{ siteId?: string }>()
  const { self } = useSelf()
  const { data: site, isCalling } = useApiWithSiteId(
    SiteGetSite,
    siteId || false,
  )

  const siteBrandIds = site?.brandIds || []

  return (
    <div className="relative">
      {isCalling && <Loading overlay size="xxl" />}

      <Inline alignX="between" width="full">
        <div className="text-lg">Templates</div>

        <div>
          <Inline space="xs">
            <Button
              disabled={isCalling}
              onClick={goBack}
              outline
              variant="neutral"
            >
              Back
            </Button>
          </Inline>
        </div>
      </Inline>

      <div className="mt-3">
        <TemplateImport brandIds={siteBrandIds} />
        {self?.isAdmin && siteId && <TemplateStatusMod />}
      </div>
    </div>
  )
}

TemplateForm.propTypes = {}

export { TemplateForm }
