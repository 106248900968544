import React, { useEffect, useState } from 'react'
import { ActionModal, FormLayout, Select } from '@therms/atalaya'
import {
  getSite,
  modTemplateStatus,
  Site,
  SiteTemplateStatus,
} from '@src/generatedClient/generated/taskreportsApi'
import { Alerts } from '@src/components/Alerts'
import { useParams } from 'react-router-dom'

function TemplateStatusMod() {
  const [templateStatus, setTemplateStatus] = useState<
    SiteTemplateStatus | undefined
  >()
  const [showModal, setShowModal] = useState(false)
  const [targetTemplateStatus, setTargetTemplateStatus] = useState('')
  const [currentSite, setCurrentSite] = useState<Site | undefined>()
  const { siteId } = useParams<{ siteId?: string }>()

  useEffect(() => {
    setTemplateStatus(currentSite?.templateStatus)
  }, [currentSite])

  useEffect(() => {
    const call = async () => {
      try {
        const site = await getSite({ id: siteId })
        setCurrentSite(site.data)
      } catch (err) {
        console.error(err)
      }
    }
    call()
  }, [siteId])

  const templateStatuses = [
    {
      label: 'Active (Non-template)',
      value: '',
    },
    {
      label: 'Template-Open',
      value: SiteTemplateStatus.OpenTemplate,
    },
    {
      label: 'Template-Locked',
      value: SiteTemplateStatus.LockedTemplate,
    },
  ]

  const modifyFollowthrough = async () => {
    try {
      const status = targetTemplateStatus
        ? SiteTemplateStatus[targetTemplateStatus]
        : undefined
      await modTemplateStatus({ siteId: currentSite.id, status })
      setTemplateStatus(status)
    } catch (error) {
      console.error(error)
      Alerts.error('Unable to modify template status')
    } finally {
      setShowModal(false)
    }
  }

  const modifyTemplateStatus = (val: string) => {
    setTargetTemplateStatus(val)
    setShowModal(true)
  }

  return (
    <>
      <FormLayout dividers="space">
        <FormLayout.Section heading="Site Template Status">
          <Select
            name="templateStatus"
            options={templateStatuses}
            onChangeValue={(value) => {
              modifyTemplateStatus(value)
            }}
            value={templateStatus || ''}
            onChange={null}
          />
        </FormLayout.Section>
      </FormLayout>

      {showModal && (
        <ActionModal
          actionText="Modify Status"
          body="Template sites are not billed, but do not run daily tasks."
          onCancel={() => setShowModal(false)}
          onAction={modifyFollowthrough}
          title="Are you sure you want to continue?"
        />
      )}
    </>
  )
}

export { TemplateStatusMod }
