import * as generatedApi from '@src/generatedClient/generated/taskreportsApi'


// Export all types from generated API
export type RefreshTokenAuthenticatedResponseBody = generatedApi.LoginSuccess
export type SitePosition = generatedApi.SitePosition
export type SiteAdminPriv = generatedApi.SiteAdminPriv
export type OrganizationGetOrganizationOkResponseBody = generatedApi.Organization
export type OrganizationModifyOrganizationModifiedResponseBody = generatedApi.Organization
export type PositionGetPositionOkResponseBody = generatedApi.Position
export type TaskGroupGetTaskGroupOkResponseBody = generatedApi.TaskGroup
export type GetTaskGroupsOkResponseBody = generatedApi.TaskGroupList
export type TaskGroupCreateTaskGroupRequestBody = generatedApi.CreateTaskGroup
export type TaskGroupCreateTaskGroupCreatedResponseBody = generatedApi.TaskGroup
export type TaskGroupModifyTaskGroupRequestBody = generatedApi.ModifyTaskGroup
export type TaskGroupModifyTaskGroupModifiedResponseBody = generatedApi.TaskGroup
export type TaskGroupDeleteTaskGroupDeletedResponseBody = void
export type GetSiteSubscribedUsersOkResponseBody = generatedApi.User[]
export type PositionCreatePositionRequestBody = generatedApi.CreatePosition
export type PositionCreatePositionCreatedResponseBody = generatedApi.Position
export type PositionModifyPositionRequestBody = generatedApi.ModifyPosition
export type PositionModifyPositionModifiedResponseBody = generatedApi.Position
export type PositionDeletePositionDeletedResponseBody = void
export type SiteGetSiteOkResponseBody = generatedApi.Site
export type SiteCreateSiteRequestBody = generatedApi.CreateSite
export type SiteCreateSiteCreatedResponseBody = generatedApi.Site
export type SiteModifySiteRequestBody = generatedApi.ModifySite
export type SiteModifySiteModifiedResponseBody = generatedApi.Site
export type SiteDeleteSiteDeletedResponseBody = void
export type UserGetUserOkResponseBody = generatedApi.User
export type Task = generatedApi.Task
export type TaskMissAction = generatedApi.TaskMissAction
export type GetTasksOkResponseBody = generatedApi.TaskList
export type TaskCreateTaskCreateRequestBody = generatedApi.CreateTask
export type TaskModifyTaskRequestBody = generatedApi.ModifyTask
export type TasksLoadTaskCompletionsOkResponseBody = generatedApi.CompletionList
export type GetActiveBillingOkResponseBody = generatedApi.OrgBillingDetails[]
export type GetCurrentPaymentMethodOkResponseBody = generatedApi.GetCurrentPaymentMethod200
export type GetInvoicesOkResponseBody = generatedApi.Invoice[]

// Wrap all the generated API methods with existing names
export const RefreshToken = () => generatedApi.refreshUserToken()

export const GetOrganization = () => generatedApi.getOrganization()

export const OrganizationModifyOrganization = (
    data: generatedApi.OrganizationEdit,
) => generatedApi.modifyOrganization(data)

export const PositionGetPosition = (id: string, config?: any) =>
    generatedApi.getPosition({ id }, config)

export const TaskGroupGetTaskGroup = (id: string, config?: any) =>
    generatedApi.getTaskGroup({ id }, config)

export const GetTaskGroups = (params?: {
  offset?: number
  limit?: number
  siteId: string
}) => generatedApi.getTaskGroups(params)

export const TaskGroupCreateTaskGroup = (
    data: generatedApi.CreateTaskGroup,
) => generatedApi.createTaskGroup(data)

export const TaskGroupModifyTaskGroup = (
    data: generatedApi.ModifyTaskGroup,
) => generatedApi.modifyTaskGroup(data)

export const TaskGroupDeleteTaskGroup = (id: string) =>
    generatedApi.deleteTaskGroup({ id })

export const TaskDeleteTask = (id: string) => generatedApi.deleteTask({ id })

export const SiteGetSiteSubscribedUsers = (siteId: string) =>
    generatedApi.getSiteSubscribed({ site: siteId })

export const PositionCreatePosition = (
    data: generatedApi.CreatePosition,
) => generatedApi.createPosition(data)

export const PositionModifyPosition = (
    data: generatedApi.ModifyPosition,
) => generatedApi.modifyPosition(data)

export const PositionDeletePosition = (id: string) =>
    generatedApi.deletePosition({ id })

export const SiteGetSite = (id: string, config?: any) =>
    generatedApi.getSite({ id }, config)

export const Logout = () => generatedApi.logoutUser()

export const SiteCreateSite = (data: generatedApi.CreateSite) =>
    generatedApi.createSite(data)

export const SiteModifySite = (data: generatedApi.ModifySite) =>
    generatedApi.modifySite(data)

export const SiteDeleteSite = (id: string) => generatedApi.deleteSite({ id })

export const AdminSetPassword = (data: generatedApi.AdminSetPassword) =>
    generatedApi.adminSetPassword(data)

export const UserGetUser = (
    params: { userId: string; siteId: string },
    config?: any,
) => generatedApi.getUserBySite(params, config)

export const UserGetSelf = () => generatedApi.getSelf()

export const UserModUserAtSite = (data: generatedApi.UserModPosition) =>
    generatedApi.userSitePositionMod(data)

export const UserModUserDetails = (data: generatedApi.ModifyUserDetails) =>
    generatedApi.userSiteMod(data)

export const UserModUserReports = (data: generatedApi.UserMod) =>
    generatedApi.userReportMod(data)

export const UserModUserSiteAdmin = (data: generatedApi.UserModSiteAdmin) =>
    generatedApi.userSiteAdminMod(data)

export const UserModUserGlobalAdmin = (data: generatedApi.UserModGlobalAdmin) =>
    generatedApi.userGlobalAdminMod(data)

export const UserWallboardLoginMod = (data: generatedApi.UserMod) =>
    generatedApi.userWallboardLoginMod(data)


export const GetPositions = (params?: {
  offset?: number
  limit?: number
  siteId: string
}) => generatedApi.getPositions(params)

export const GetSites = (params?: {
  offset?: number
  limit?: number
  withPositions?: boolean
}) => generatedApi.getSites(params)

export const GetUsersBySiteId = (params?: {
  offset?: number
  limit?: number
  siteId: string
}) => generatedApi.getUsersBySite(params)

export const GetOrganizationUsers = (params?: {
  offset?: number
  limit?: number
}) => generatedApi.getUsersByOrg(params)

export const LoginUser = (data: generatedApi.UserLogin) =>
    generatedApi.loginUserF(data)

export const SuperuserGetAllOrgs = (data: generatedApi.SuperuserGetAllOrganizationsBody) =>
    generatedApi.superuserGetAllOrgs(data)

export const SuperuserGetOrgOwnerJwt = (data: generatedApi.SuperuserGetOwnerJwtBody) =>
    generatedApi.superuserGetOwnerJwt(data)

export const SuperuserDeactivateOrg = (data: generatedApi.SuperuserGetOwnerJwtBody) =>
    generatedApi.superuserDeactivateOrg(data)

export const SuperuserSetPricing = (data: generatedApi.SuperuserSetPricingBody) =>
    generatedApi.superuserSetPricing(data)

export const RegisterOrganization = (
    data: generatedApi.Registration,
) => generatedApi.register(data)

export const ResetPassword = (data: { email: string }) =>
    generatedApi.requestReset({ email: data.email })

export const TasksLoadTaskCompletions = (params: {
  offset?: number
  limit?: number
  addVerificationFileUrls?: boolean
  endDate: string
  startDate: string
  status?: generatedApi.CompletionStatus
  showOnsiteUsersForMissed?: boolean
  showOnlyMissed?: boolean
  showOnlySkipped?: boolean
  siteId?: string
  taskId?: string
  taskGroupId?: string
  userId?: string
}) => generatedApi.getAllCompletions({...params, site:params.siteId, showForTaskGroupId:params.taskGroupId || undefined, showForTaskId:params.taskId || undefined, showForUserId:params.userId || undefined})

export const EvaluateTaskCompletion = (data: {
  completionId: string
  sentiment: 'positive' | 'negative'
  siteId: string
  note: string
}) => generatedApi.completionEval(data)

export const SiteGetWhoWasThere = (params: {
  endDate: string
  siteId: string
  startDate: string
}) => generatedApi.getWhoWasThere(params)

export const GetEscalations = (params?: {
  siteId: string
  offset?: number
  limit?: number
}) => generatedApi.getEscalations(params)

export const GetEscalation = (id: string, config?: any) =>
    generatedApi.getEscalation({ id }, config)

export const CreateEscalation = (data: generatedApi.CreateEscalation) =>
    generatedApi.createEscalation(data)

export const ModifyEscalation = (data: generatedApi.ModifyEscalation) =>
    generatedApi.modifyEscalation(data)

export const DeleteEscalation = (id: string) =>
    generatedApi.deleteEscalation({ id })

export const EmailEscOccurrenceToExternal = (
    escOccurrence: generatedApi.EmailEscOccurrence,
) => generatedApi.emailEscOccurrence(escOccurrence)

export const GetEscOccurencesByTimeRange = (
    params: {
      addVerificationFileUrls?: boolean
      endDate: string
      isResolved?: boolean
      limit?: number
      offset?: number
      startDate: string
      siteId?: string
    },
    config?: any,
) => generatedApi.getEscOccurrences({...params, site:params.siteId}, config)

export const ResolveEsOccurrence = (data: {
  siteId: string
  escOccuranceId: string
  adminNote: string
}) => generatedApi.resolveEscOccurrence(data)

export const TaskMarkComplete = (data: generatedApi.MarkTaskComplete) =>
    generatedApi.markComplete(data)

export const CheckForBotToken = (botTargetKey: string) =>
    generatedApi.checkForBotToken({ botTargetKey })

export const AuthorizeSiteReadBot = (data: {
  botTargetKey: string
  siteId: string
}) => generatedApi.authorizeSingeSiteReadBot(data)

export const GetConductReport = (params: {
  endDate: string
  limit?: number
  offset?: number
  startDate: string
  siteId: string
}) => generatedApi.conductReport(params)

export const GetComments = (params: { commentIds: string[]; siteId: string }) =>
    generatedApi.loadCommentsByIds(params)

export const GetBrands = () => generatedApi.getBrands()

export const GetAllOrgTemplates = (data: { siteId: string }) =>
    generatedApi.allOrgTemplates({site:data.siteId})

export const UseOrgTemplate = (data: generatedApi.UseOrgTemplate) =>
    generatedApi.useOrgTemplate(data)

export const RemoveOrgTemplate = (data: generatedApi.UseOrgTemplate) =>
    generatedApi.removeOrgTemplate(data)

export const GetRollingStatsReport = (data: generatedApi.RollingStatsReportParams) =>
    generatedApi.rollingStatsReport(data)

export const GetRollingStatsBreakdown = (
    data: generatedApi.RollingStatsBreakdownParams,
) => generatedApi.rollingStatsBreakdown(data)

export const GetContactsBySite = (params: generatedApi.GetContactsBySiteParams) =>
    generatedApi.getContactsBySite(params)

export const GetAllContacts = (params: generatedApi.AllContactsParams) =>
    generatedApi.allContacts(params)

export const GetContact = (contactId: string) =>
    generatedApi.getContact({ id: contactId })

export const CreateContact = (contact: generatedApi.CreateContact) =>
    generatedApi.createContact(contact)

export const ModifyContact = (contact: generatedApi.ModifyContact) =>
    generatedApi.modifyContact(contact)

export const DeleteContact = (contactId: string) =>
    generatedApi.deleteContact({ id: contactId })

export const SendTestEmailToExternal = (
    body: generatedApi.EmailTestMessage,
) => generatedApi.sendTestEmailToExternal(body)

export const WallboardGetLoginToken = (siteId: string) =>
    generatedApi.wallboardGetLoginToken({ siteId })

export const WallboardLoginUsers = (token: string) =>
    generatedApi.wallboardLoginUsers({ token })

export const GetEmailPreferences = (id: string) =>
    generatedApi.getEmailPrefs({ id })

export const ModifyEmailPreferences = (emailPreferences: generatedApi.ModEmailPreferences) =>
    generatedApi.modEmailPrefs(emailPreferences)

export const GetActiveBilling = () => generatedApi.getActiveBilling()

export const GetCurrentPaymentMethod = () => generatedApi.getCurrentPaymentMethod()

export const GetInvoices = (data: {offset:number}) => generatedApi.getInvoices(data)

export const SetPaymentMethod = (
    data: generatedApi.SetPaymentMethodBody,
) => generatedApi.setPaymentMethod(data)

export const ModifySubscription = (
    data: generatedApi.ModifySubscriptionStatusBody,
) => generatedApi.modifySubscriptionStatus(data)

export const GetUserCompletions = (params: {
  endDate: string
  siteId: string
  taskId?: string
}) => generatedApi.getUserCompletions(params)

export const TaskGetTask = (
    params: {
      siteId: string
      taskId: string
      withPositions?: boolean
    },
    config?: any,
) => generatedApi.getTask({
  id: params.taskId,
  resolvePositions: params?.withPositions,
  resolveTaskGroups: true,
  resolveEscalations: true,
}, config)

export const TaskCreateTask = (data: generatedApi.CreateTask) =>
    generatedApi.createTask(data)

export const TaskModifyTask = (data: generatedApi.ModifyTask) =>
    generatedApi.modifyTask(data)

export const GetOrgTemplateUsages = () =>
    generatedApi.getOrganization()

export type OrgTemplateUsage = generatedApi.OrgTemplateData

export const SiteSnapshotForAdmin = (siteId: string) =>
    generatedApi.siteSnapshotForAdmin({ siteId })

export const GetTasks = (params?: {
  offset?: number
  limit?: number
  taskGroupId?: string
  siteId: string
}) => generatedApi.getTasks({
    siteId: params.siteId,
    offset: params?.offset,
    limit: params?.limit,
    includesTaskGroupId: params?.taskGroupId || undefined,
  })
