import React from 'react'
import { generatePath, NavLink, useParams } from 'react-router-dom'
import { MdPhonelinkSetup } from 'react-icons/md'
import {
  Badge,
  Button,
  ButtonGroup,
  CopyText,
  Inline,
  Label,
  Loading,
  Tabs,
} from '@therms/atalaya'
import { SITES_ROUTES } from '@src/modules/Sites/constants'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'
import {
  SiteGetSite,
  SiteGetSiteOkResponseBody,
} from '@src/services/api-client'
import { useSiteSubscribedUsers } from '@src/modules/Sites/hooks/useSiteSubscribedUsers'
import { PositionName } from '@src/modules/Positions/shared/PositionName'
import { UsersOnSiteHistoryTable } from '@src/modules/Sites/router/screens/ViewSite/components/UsersOnSiteHistoryTable'
import { ButtonWithModal } from '@src/components/ButtonWithModal'
import { SetupWallboardDeviceModal } from '@src/modules/Sites/router/screens/ViewSite/components/SetupWallboardDeviceModal'
import { SiteHours } from '@src/modules/Sites/router/screens/ViewSite/components/SiteHours'
import { useAuth } from '@src/modules/Auth/shared/state'
import { genSiteUser } from '@src/modules/Auth/shared/siteUser'
import { PositionsTable } from '@src/modules/Positions/router/screens/Index'
import { UsersTable } from '@src/modules/Users/router/screens/Index/components/Users'
import { ContactsTable } from '@src/modules/Contacts/router/screens/Index'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'

function ViewSite() {
  const { siteId } = useParams<{ siteId?: string }>()
  const { data } = useApiWithSiteId<SiteGetSiteOkResponseBody>(
    SiteGetSite,
    siteId,
  )
  const { subscribed } = useSiteSubscribedUsers(siteId)
  const { currentSite } = useAuth()
  if (!data) return <Loading overlay size="xxl" />

  return (
    <div>
      <Inline alignX="between" width="full">
        <div className="text-lg">Site Info</div>

        <div>
          <Inline space="xs">
            <ButtonGroup>
              <ButtonWithModal
                modalElement={<SetupWallboardDeviceModal siteId={siteId} />}
                variant="main"
                subtle
              >
                <MdPhonelinkSetup /> Setup Wallboard Device
              </ButtonWithModal>
              01
              <NavLink to={generatePath(SITES_ROUTES.EDIT_SITE, { siteId })}>
                <Button outline variant="neutral">
                  Edit
                </Button>
              </NavLink>
              <NavLink
                to={generatePath(SITES_ROUTES.SITE_TEMPLATES, { siteId })}
              >
                <Button className="ml-2" outline variant="info">
                  Templates
                </Button>
              </NavLink>
            </ButtonGroup>
          </Inline>
        </div>
      </Inline>

      <Tabs initialTabKey="Info">
        <Tabs.Tab title="Info">
          <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
            <div>
              <Label>Site Name</Label>
              <div>{data.name}</div>
            </div>

            <div>
              <Label>Address</Label>
              <div className="whitespace-pre">
                <CopyText alwaysShow value={data.address}>
                  {data.address}
                </CopyText>
              </div>
            </div>

            <div>
              <Label>Timezone</Label>
              <div>{data.timezone}</div>
            </div>

            <SiteHours site={data} />

            {data.emailToCCOnContactRequests?.length > 0 && (
              <div>
                <Label>Emails to CC for Escalations</Label>
                <div>{data.emailToCCOnContactRequests?.join(', ')}</div>
              </div>
            )}

            <div>
              <Label>Allow Login using Wallboard</Label>
              {data.wallboardSettings?.qrCodeSignIn ? (
                <AiOutlineCheck size={20} className="text-color-positive" />
              ) : (
                <AiOutlineClose size={20} className="text-color-critical" />
              )}
            </div>
          </div>
        </Tabs.Tab>

        <Tabs.Tab title="Positions">
          <PositionsTable />
        </Tabs.Tab>

        <Tabs.Tab title="Site Users">
          <Tabs initialTabKey="Currently On-Site" pills>
            <Tabs.Tab title="Currently On-Site">
              <div>
                {subscribed.length === 0 ? (
                  <div className="mt-base text-center text-lg">
                    There are no users currently on-site
                  </div>
                ) : (
                  <Label>
                    Currently On-Site Users <Badge value={subscribed.length} />
                  </Label>
                )}

                <div>
                  {subscribed.map((subscribedUser) => (
                    <div>
                      <strong>
                        <PositionName
                          id={
                            genSiteUser(subscribedUser, currentSite.id)
                              .positionId || ''
                          }
                        />
                      </strong>{' '}
                      <span>
                        {subscribedUser.name} ({subscribedUser.email})
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </Tabs.Tab>

            <Tabs.Tab title="On-Site History">
              <UsersOnSiteHistoryTable siteId={siteId} />
            </Tabs.Tab>

            <Tabs.Tab title="User List">
              <UsersTable />
            </Tabs.Tab>
          </Tabs>
        </Tabs.Tab>

        <Tabs.Tab title="Contacts">
          <ContactsTable siteId={siteId} />
        </Tabs.Tab>
      </Tabs>
    </div>
  )
}

export { ViewSite }
