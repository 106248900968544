import React from 'react'
import {Button, Inline, Loading, Tabs} from '@therms/atalaya'
import {UserProfileFormSection} from '@src/modules/Users/router/screens/UserForm/components/FormSections/Profile'
import {UserConfigFormSection} from '@src/modules/Users/router/screens/UserForm/components/FormSections/Config'
import {useHistory, useParams} from 'react-router-dom'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {UserGetUser} from '@src/services/api-client'
import {useApiWithSiteId} from '@src/hooks/useApiWithSiteId'

const ValidationSchema = Yup.object().shape({
  email: Yup.string().email().required('Email required'),
  name: Yup.string().min(2).required('Name must be at least 3 characters long'),
})

function UserForm() {
  const { goBack } = useHistory()
  const { userId } = useParams<{ userId?: string }>()

  const { data } = useApiWithSiteId(UserGetUser, userId ? { userId } : false)

  const user = data

  const save = (values) => {
    console.log(values)
  }
  // const save = useCallback(async (values) => {
  //   try {
  //     if (userId) {
  //       await UserModifyUser({
  //         id: userId,
  //         active: !!values.active,
  //         email: values.email,
  //         isAdmin: values.isAdmin,
  //         phone: values.phone,
  //         name: values.name,
  //         positionId: values.positionId,
  //         siteIds: values.siteIds || [],
  //         siteIdsRecReportsFrom: values.siteIdsRecReportsFrom || [],
  //       })
  //     } else {
  //       await UserCreateUser({
  //         active: !!values.active,
  //         email: values.email,
  //         isAdmin: values.isAdmin,
  //         phone: values.phone,
  //         name: values.name,
  //         positionId: values.positionId,
  //         siteIds: values.siteIds || [],
  //         siteIdsRecReportsFrom: values.siteIdsRecReportsFrom || [],
  //       })
  //     }

  //     Alerts.success(`User ${userId ? 'updated' : 'created'}`)

  //     goBack()
  //   } catch (err) {
  //     Alerts.error('There was a problem saving the User', err.message)

  //     console.log('save() err', err)
  //   }
  // }, [])

  return (
    <Formik
      enableReinitialize
      initialValues={user || { id: userId }}
      onSubmit={save}
      validateOnChange={false}
      validationSchema={ValidationSchema}
    >
      {({ isValid, submitForm, isSubmitting, values }) => (
        <div className="relative">
          {!user && userId && <Loading overlay size="xxl" />}
          {isSubmitting && <Loading overlay size="xxl" />}

          <Inline alignX="between" width="full">
            <Inline alignY="center">
              <div className="">
                <div className="text-lg">
                  {userId ? 'Edit User' : 'Create User'}
                </div>
              </div>
            </Inline>

            <div>
              <Inline space="xs">
                <Button
                  disabled={isSubmitting}
                  onClick={goBack}
                  outline
                  variant="neutral"
                >
                  Cancel
                </Button>

                <Button
                  disabled={!isValid}
                  loading={isSubmitting}
                  onClick={submitForm}
                  variant="main"
                >
                  Save
                </Button>
              </Inline>
            </div>
          </Inline>

          <div className="my-5" />

          <Tabs initialTabKey="Profile">
            <Tabs.Tab title="Profile">
              <UserProfileFormSection />
            </Tabs.Tab>

            <Tabs.Tab title="Config">
              <UserConfigFormSection />
            </Tabs.Tab>
          </Tabs>
        </div>
      )}
    </Formik>
  )
}

UserForm.propTypes = {}

export { UserForm }
