import React, { useCallback } from 'react'
import { Button, Inline, Loading } from '@therms/atalaya'
import { SiteInfoFormSection } from '@src/modules/Sites/router/screens/SiteForm/components/FormSections/Info'
import { useHistory, useParams } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Alerts } from '@src/components/Alerts'
import {
  SiteCreateSite,
  SiteGetSite,
  SiteModifySite,
} from '@src/services/api-client'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'

import { DeleteSiteButtonActionModal } from '@src/modules/Sites/shared/DeleteSiteButtonActionModal'

const ValidationSchema = Yup.object().shape({
  address: Yup.string().required('Address required'),
  name: Yup.string().min(3).required('Name must be at least 3 characters long'),
  timezone: Yup.string().required('Timezone required'),
})

function SiteForm() {
  const { goBack } = useHistory()
  const { siteId } = useParams<{ siteId?: string }>()

  const { data: site, isCalling } = useApiWithSiteId(
    SiteGetSite,
    siteId || false,
  )

  const siteBrandIds = site?.brandIds || []

  const save = useCallback(async (values) => {
    try {
      if (siteId) {
        await SiteModifySite({
          address: values.address,
          emailToCCOnContactRequests: values.emailToCCOnContactRequests,
          id: values.id,
          name: values.name,
          timezone: values.timezone,
          siteTagIds: values.siteTagIds,
          storeHours: values.storeHours,
          wallboardSettings: {
            qrCodeSignIn: !!values.wallboardSettings?.qrCodeSignIn,
          },
        })
      } else {
        await SiteCreateSite({
          address: values.address,
          emailToCCOnContactRequests: values.emailToCCOnContactRequests,
          name: values.name,
          storeHours: values.storeHours,
          siteTagIds: values.siteTagIds,
          timezone: values.timezone,
          wallboardSettings: {
            qrCodeSignIn: !!values.wallboardSettings?.qrCodeSignIn,
          },
          zipCode: '',
        })
      }

      Alerts.success(`Site ${siteId ? 'updated' : 'created'}`)
      if (siteId) goBack()
      else window.location.href = '/'
    } catch (err) {
      Alerts.error('There was a problem saving the Site', err.message)

      console.log('save() err', err)
    }
  }, [])

  return (
    <Formik
      enableReinitialize
      initialValues={
        site || {
          storeHours: null,
        }
      }
      onSubmit={save}
      validateOnChange={false}
      validationSchema={ValidationSchema}
    >
      {({ isValid, submitForm, isSubmitting, values }) => (
        <div className="relative">
          {(isCalling || isSubmitting) && <Loading overlay size="xxl" />}

          <Inline alignX="between" width="full">
            <div className="text-lg">
              {!siteId ? 'Create New Site' : 'Edit Site'}
            </div>

            <div>
              <Inline space="xs">
                {siteId && (
                  <DeleteSiteButtonActionModal
                    onComplete={goBack}
                    siteId={siteId}
                  />
                )}

                <Button
                  disabled={isSubmitting}
                  onClick={goBack}
                  outline
                  variant="neutral"
                >
                  Cancel
                </Button>

                <Button
                  disabled={!isValid}
                  loading={isSubmitting}
                  onClick={submitForm}
                  variant="main"
                >
                  Save
                </Button>
              </Inline>
            </div>
          </Inline>

          <div className="mt-3">
            <SiteInfoFormSection />
          </div>
        </div>
      )}
    </Formik>
  )
}

SiteForm.propTypes = {}

export { SiteForm }
