import React, {StrictMode} from 'react'
import {Router} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import {AtalayaWrapper} from '@therms/atalaya'
import {SWRConfig} from 'swr'
import {apiClient} from '@src/services/api-client-instance'
import {UrlRoutesWrapper} from '@src/UrlRoutesWrapper'

const history = createBrowserHistory()

export const LightTheme = {
  Background: '#ffffff',
  Border: '#D4DADD',
  Caution: '#F9B21F',
  CautionDark: '#BD7F00',
  CautionFaded: '#FAB11E66',
  CautionLight: '#FFC857',
  Critical: '#E5401A',
  CriticalDark: '#8c260e',
  CriticalFaded: '#E5401A66',
  CriticalLight: '#fd623c',
  Info: '#2694d9',
  InfoDark: '#1c6f9c',
  InfoFaded: 'rgba(38,163,217,0.4)',
  InfoLight: '#7dc3e8',
  Main: '#1f2e49',
  MainDark: '#111928',
  MainFaded: 'rgba(1,61,138,0.4)',
  MainLight: 'rgba(23,74,154,0.56)',
  Neutral: '#8FA0A3',
  NeutralDark: '#819498',
  NeutralFaded: '#8fa0a366',
  NeutralLight: '#C6CFD2',
  Overlay: '#0C1114BF',
  Positive: '#2EB87C',
  PositiveDark: '#196645',
  PositiveFaded: '#2EB87C66',
  PositiveLight: '#70DBAD',
  Surface: '#e8ebed',
  SurfaceStrong: '#dee1e3',
  SurfaceSubtle: '#f0f2f2',
  Text: '#00111ABF',
  TextSemantic: '#FFFFFF',
  TextStrong: '#00111AF2',
  TextSubtle: '#00111A73',
  ScrollbarTrack: '#d4d9d9',
  ScrollbarThumb: '#afb3b3',
  ScrollbarThumbHighlight: '#bbbfbf',
  InputBackground: '#ccd5d7',
}

export function App() {
  return (
    <StrictMode>
      <AtalayaWrapper
        defaultTheme="light"
        theme={{
          dark: LightTheme,
          light: LightTheme,
        }}
      >
        <Router history={history}>
          <SWRConfig
            value={{
              fetcher: (resource, init) =>
                apiClient(resource, init).then((res) => res.data),
            }}
          >
            <UrlRoutesWrapper />
          </SWRConfig>
        </Router>
      </AtalayaWrapper>
    </StrictMode>
  )
}
