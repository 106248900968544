import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Checkbox,
  DateTimePicker,
  FormLayout,
  Select,
  Text,
} from '@therms/atalaya'
import { timezones } from '@src/utils/timezones'
import { FormikText } from '@src/components/FormikFields/FormikText'
import { FormikSelect } from '@src/components/FormikFields/FormikSelect'
import { useFormikContext } from 'formik'
import dayjs from 'dayjs'
import { RRule } from 'rrule'
import { SiteCreateSiteRequestBody } from '@src/services/api-client'
import { FormikCheckbox } from '@src/components/FormikFields/FormikCheckbox'
import {
  getSite,
  getSiteTagsWithType,
  SiteTagType,
  SiteTagWithType,
  SiteTemplateStatus,
} from '@src/generatedClient/generated/taskreportsApi'
import { Alerts } from '@src/components/Alerts'
import { useParams } from 'react-router-dom'

function SiteInfoFormSection() {
  const form = useFormikContext<SiteCreateSiteRequestBody>()
  const siteTagIds = form.values.siteTagIds || []
  const { siteId } = useParams<{ siteId?: string }>()
  const [siteTags, setSiteTags] = useState<SiteTagWithType[]>([])
  const [siteTagTypeMap, setSiteTagTypeMap] = useState<{
    [k: string]: SiteTagWithType[]
  }>({})
  const [templateStatus, setTemplateStatus] = useState<
    SiteTemplateStatus | undefined
  >()
  const [siteTagMap, setSiteTagMap] = useState<{
    [k: string]: SiteTagWithType
  }>({})
  const [siteTagTypes, setSiteTagTypes] = useState<SiteTagType[]>([])

  const handleTagSelect = useCallback(
    (tagId: string, tagTypeId: string) => {
      // Add tagTypeId parameter
      if (!tagId) {
        // If "None" is selected, remove any existing tag of this type
        form.setFieldValue(
          'siteTagIds',
          siteTagIds.filter((id) => siteTagMap[id].siteTagTypeId !== tagTypeId),
        )
        return
      }

      const tag = siteTagMap[tagId]
      if (!tag) return

      // Remove any existing tag of the same type and add the new one
      const filteredTags = siteTagIds.filter(
        (id) => siteTagMap[id].siteTagTypeId !== tag.siteTagTypeId,
      )
      form.setFieldValue('siteTagIds', [...filteredTags, tagId])
    },
    [form, siteTagIds, siteTagMap],
  )

  useEffect(() => {
    const call = async () => {
      try {
        const site = await getSite({ id: siteId })
        setTemplateStatus(site.data.templateStatus)
      } catch (err) {
        console.error(err)
      }
    }
    call()
  }, [siteId])

  useEffect(() => {
    const call = async () => {
      try {
        const res = await getSiteTagsWithType({ siteId })
        setSiteTags(res.data)
        const sMap: { [k: string]: SiteTagWithType[] } = {}
        const tMap: { [k: string]: SiteTagWithType } = {}
        const types: SiteTagType[] = []
        for (const st of res.data) {
          tMap[st.id] = st
          if (sMap[st.siteTagTypeId] === undefined) {
            types.push(st.siteTagType)
            sMap[st.siteTagTypeId] = []
          }
          sMap[st.siteTagTypeId].push(st)
        }
        setSiteTagTypeMap(sMap)
        setSiteTagTypes(types)
        setSiteTagMap(tMap)
      } catch (err) {
        console.error(err)
        Alerts.error('Unable to load tag data')
      }
    }
    call()
  }, [siteId])

  const storeHoursTime = useMemo(() => {
    try {
      if (!form.values.storeHours) return {}

      const d = new Date()

      const openRruleRaw = form.values.storeHours.storeOpeningRules
      const closeRruleRaw = form.values.storeHours.storeClosingRules
      const openRrule =
        typeof openRruleRaw === 'string' ? [openRruleRaw] : openRruleRaw
      const closeRrule =
        typeof closeRruleRaw === 'string' ? [closeRruleRaw] : closeRruleRaw
      let open = ''
      let close = ''

      if (openRrule.length) {
        const rr = RRule.fromString(openRrule[0]).options
        if (rr.byminute?.length && rr.byhour?.length) {
          d.setMinutes(rr.byminute[0])
          d.setHours(rr.byhour[0])
        } else {
          d.setMinutes(0)
        }

        open = d.toISOString()
      }

      if (closeRrule.length) {
        const rr = RRule.fromString(closeRrule[0]).options

        if (rr.byminute?.length && rr.byhour?.length) {
          d.setMinutes(rr.byminute[0])
          d.setHours(rr.byhour[0])
        } else {
          d.setMinutes(0)
        }

        close = d.toISOString()
      }

      return open && close
        ? { open, close }
        : {
            close: dayjs().set('hour', 22).set('minute', 0).toISOString(),
            open: dayjs().set('hour', 6).set('minute', 0).toISOString(),
          }
    } catch (err) {
      console.error('RRULE error', err)
      return {}
    }
  }, [form.values.storeHours])

  return (
    <FormLayout dividers="space">
      <FormLayout.Section heading="Info">
        <FormikText label="Name" name="name" />

        <FormikSelect
          label="Timezone"
          name="timezone"
          placeholder="Select Timezone..."
          options={timezones.map(({ label, tzCode }) => ({
            label,
            value: tzCode,
          }))}
        />

        <Text
          label="CC Emails"
          subText="Emails we will cc for escalation emails sent to contacts for this site"
          onChangeValue={(value) => {
            const emails = value.split(', ')
            form.setFieldValue(
              'emailToCCOnContactRequests',
              value ? emails : [],
            )
          }}
          search
          value={form.values.emailToCCOnContactRequests?.join(', ')}
        />

        <FormikCheckbox
          active={!!form.values.wallboardSettings?.qrCodeSignIn}
          label="Allow Login using Wallboard"
          name="wallboardSettings.qrCodeSignIn"
        />
      </FormLayout.Section>

      <FormLayout.Divider withBorder />

      <FormLayout.Section heading="Address">
        <FormikText label=" " name="address" />
      </FormLayout.Section>

      <FormLayout.Divider withBorder />

      <FormLayout.Section heading="Details">
        <div className="space-y-4">
          {siteTagTypes.map((tagType) => (
            <Select
              key={tagType.id}
              label={tagType.title}
              name={`tag-${tagType.id}`}
              placeholder={`Select ${tagType.title}...`}
              options={[
                { label: 'None', value: '' },
                ...(siteTagTypeMap[tagType.id]?.map((tag) => ({
                  label: tag.title,
                  value: tag.id,
                })) || []),
              ]}
              value={
                siteTagIds.find(
                  (id) => siteTagMap[id]?.siteTagTypeId === tagType.id,
                ) || ''
              }
              onChangeValue={(value) => handleTagSelect(value, tagType.id)}
              onChange={null}
            />
          ))}
        </div>
      </FormLayout.Section>

      <FormLayout.Divider withBorder />

      <FormLayout.Section heading="Store Hours">
        <Checkbox
          active={!form.values.storeHours}
          label="This Site is open 24hr"
          onChangeValue={(checked) => {
            if (!checked) {
              const dOpen = dayjs().set('hour', 6).set('minute', 0)
              const dClose = dayjs().set('hour', 22).set('minute', 0)

              form.setFieldValue('storeHours', {
                storeOpeningRules: new RRule({
                  freq: RRule.DAILY,
                  byhour: [dOpen.get('hour')],
                  byminute: [dOpen.get('minute')],
                  bysecond: [0],
                  interval: 1,
                }).toString(),
                storeClosingRules: new RRule({
                  freq: RRule.DAILY,
                  byhour: [dClose.get('hour')],
                  byminute: [dClose.get('minute')],
                  bysecond: [0],
                  interval: 1,
                }).toString(),
              })
            } else {
              form.setFieldValue('storeHours', null)
            }
          }}
        />

        {form.values.storeHours && (
          <>
            <DateTimePicker
              mode="time"
              label="Open"
              onChangeValue={(time) => {
                const d = new Date(time)

                const rruleStr = new RRule({
                  freq: RRule.DAILY,
                  byhour: [d.getHours()],
                  byminute: [d.getMinutes()],
                  bysecond: [0],
                  interval: 1,
                }).toString()

                form.setFieldValue('storeHours.storeOpeningRules', rruleStr)
              }}
              value={storeHoursTime.open}
            />

            <DateTimePicker
              mode="time"
              label="Close"
              onChangeValue={(time) => {
                const d = new Date(time)
                const rruleStr = new RRule({
                  freq: RRule.DAILY,
                  byhour: [d.getHours()],
                  byminute: [d.getMinutes()],
                  bysecond: [0],
                  interval: 1,
                }).toString()

                form.setFieldValue('storeHours.storeClosingRules', rruleStr)
              }}
              value={storeHoursTime.close}
            />
          </>
        )}
      </FormLayout.Section>
    </FormLayout>
  )
}

export { SiteInfoFormSection }
