import React, { useState } from 'react'
import { Button, Inline, Loading, Modal } from '@therms/atalaya'
import { TaskInfoFormSection } from '@src/modules/Tasks/router/screens/TaskForm/components/FormSections/Info'
import { useHistory, useParams } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Alerts } from '@src/components/Alerts'
import {
  TaskCreateTask,
  TaskDeleteTask,
  TaskGetTask,
  TaskModifyTask,
} from '@src/services/api-client'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'
import { TaskSchedulingsContextProvider } from '@src/modules/Tasks/router/screens/TaskForm/contexts/TaskSchedulingsContext'
import { ButtonWithActionModal } from '@src/components/ButtonWithActionModal'
import { PrintTaskQRCode } from '@src/modules/Tasks/router/screens/TaskForm/components/FormSections/components/PrintTaskQRCode'
import { WriteTaskNFCTag } from '@src/modules/Tasks/router/screens/TaskForm/components/FormSections/components/WriteTaskNFCTag'
import { delErrorCheck } from '../../../../../services/api-resp-checks'
import { useAuth } from '../../../../Auth/shared/state'

const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2)
    .required('Title must be at least 2 characters long'),
})

function TaskForm() {
  const { goBack } = useHistory()
  const params = useParams<{ taskId: string }>()
  const [taskId, setTaskId] = useState(params.taskId)
  const [showPrintQRModal, setShowPrintQRModal] = useState(false)
  const [showWriteNFCTagModal, setShowWriteNFCTagModal] = useState(false)
  const { currentSite } = useAuth()

  const {
    data: task,
    isCalling,
    call,
  } = useApiWithSiteId(TaskGetTask, taskId ? { taskId } : false)

  const deleteTask = async () => {
    TaskDeleteTask(taskId)
      .then(() => {
        Alerts.success('Task deleted')
      })
      .catch((err) => delErrorCheck(err))
      .catch(() => {
        Alerts.error('Task was not deleted')
      })

    goBack()
  }

  const save = async (values) => {
    try {
      if (taskId) {
        await TaskModifyTask({
          id: values.id,
          canSkip: !!values.canSkip,
          isAnyTime: !!values.isAnyTime,
          advancedViewing: !!values.advancedViewing,
          description: values.description,
          escalationIds: values.escalationIds || [],
          missActions: values.missActions,
          title: values.title,
          orgTemplateData: values.orgTemplateData,
          onCompleteEmailPositionIds: values.onCompleteEmailPositionIds,
          positionIds: values.positionIds,
          scheduleStrings: values.scheduleStrings,
          verification: values.verification,
          taskGroupIds: values.taskGroupIds,
          difficulty: values.difficulty || 'easy',
          multiChoice: values.multiChoice || [],
        })

        Alerts.success(`Task updated`)

        goBack()
      } else {
        const { data } = await TaskCreateTask({
          canSkip: !!values.canSkip,
          isAnyTime: !!values.isAnyTime,
          advancedViewing: !!values.advancedViewing,
          siteId: currentSite.id,
          description: values.description,
          escalationIds: values.escalationIds || [],
          missActions: values.missActions,
          title: values.title,
          orgTemplateData: values.orgTemplateData,
          positionIds: values.positionIds,
          scheduleStrings: values.scheduleStrings,
          verification: values.verification,
          taskGroupIds: values.taskGroupIds,
          onCompleteEmailPositionIds: values.onCompleteEmailPositionIds,
          difficulty: values.difficulty || 'easy',
          multiChoice: values.multiChoice || [],
        })

        if (values?.verification === 'qrCode') {
          setTaskId(data.id)

          Alerts.success(
            `Task created - you can now print the verification QR code`,
          )

          setShowPrintQRModal(true)
        } else if (
          values?.verification === 'nfc' ||
          values?.verification === 'nfcAndPicture'
        ) {
          setTaskId(data.id)

          Alerts.success(
            `Task created - you can now configure the verification NFC tag`,
          )

          setShowWriteNFCTagModal(true)
        } else {
          Alerts.success(`Task created`)
          goBack()
        }
      }
    } catch (err) {
      Alerts.error('There was a problem saving the Task', err.message)

      console.log('save() err', err)
    }
  }

  // const handleEjectTemplate = async () => {
  //   try {
  //     await TaskModifyTask({
  //       ...task,
  //       orgTemplateData: {},
  //     })

  //     call()

  //     Alerts.success('Template ejected')
  //   } catch (e) {
  //     Alerts.error('There was a problem ejecting the template', e.message)
  //   }
  // }

  const taskIsATemplate = false

  return (
    <Formik
      enableReinitialize
      initialValues={
        task || {
          description: '',
          missActions: [],
          positionIds: [],
          onCompleteEmailPositionIds: [],
          scheduleStrings: [],
          verification: 'selfReport',
          taskGroupIds: [],
        }
      }
      onSubmit={save}
      validateOnChange={false}
      validationSchema={ValidationSchema}
    >
      {({ isValid, submitForm, isSubmitting, values }) => (
        <div className="relative">
          {showPrintQRModal && (
            <Modal
              closeHandler={goBack}
              escPressClose
              header="Print Task QR Code"
            >
              <PrintTaskQRCode task={values} />
            </Modal>
          )}

          {showWriteNFCTagModal && (
            <Modal
              closeHandler={goBack}
              escPressClose
              header="Configure Task NFC Tag"
            >
              <WriteTaskNFCTag task={values} />
            </Modal>
          )}

          {isCalling && <Loading overlay size="xxl" />}
          {isSubmitting && <Loading overlay size="xxl" />}

          <Inline alignX="between" width="full">
            <div className="text-lg truncate">
              {task?.title || values.title || 'Task'}
            </div>

            {!taskIsATemplate && (
              <div>
                <Inline space="xs">
                  <ButtonWithActionModal
                    actionText="Delete"
                    body="Are you sure you want to delete this Task?"
                    variant="critical"
                    title="Delete Task"
                    onAction={deleteTask}
                  />

                  <Button
                    disabled={isSubmitting}
                    onClick={goBack}
                    outline
                    variant="neutral"
                  >
                    Cancel
                  </Button>

                  <Button
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={submitForm}
                    variant="main"
                  >
                    Save
                  </Button>
                </Inline>
              </div>
            )}
          </Inline>

          <div className="mt-3">
            <TaskSchedulingsContextProvider>
              <TaskInfoFormSection />
            </TaskSchedulingsContextProvider>
          </div>
        </div>
      )}
    </Formik>
  )
}

TaskForm.propTypes = {}

export { TaskForm }
