import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Button, FormLayout, Inline, Label, Modal } from '@therms/atalaya'
import { FieldArray, useFormikContext } from 'formik'

interface MultiChoiceOption {
  display: string
  key: string
}

interface MultiChoiceQuestion {
  answer?: string
  key: string
  options: MultiChoiceOption[]
  question: string
}

// Component for editing a single multiple choice question
function MultiChoiceQuestionEditor({
  initialValue,
  onChange,
}: {
  initialValue: MultiChoiceQuestion
  onChange: (question: MultiChoiceQuestion) => void
}) {
  const [localQuestion, setLocalQuestion] = useState(initialValue)

  // Helper function to ensure changes are saved
  const saveChanges = (updatedQuestion: MultiChoiceQuestion) => {
    setLocalQuestion(updatedQuestion)
    onChange(updatedQuestion)
  }

  const addOption = () => {
    const updatedQuestion = {
      ...localQuestion,
      options: [...localQuestion.options, { display: '', key: uuidv4() }],
    }
    saveChanges(updatedQuestion)
  }

  const removeOption = (index: number) => {
    const newOptions = [...localQuestion.options]
    newOptions.splice(index, 1)
    const updatedQuestion = {
      ...localQuestion,
      options: newOptions,
    }
    saveChanges(updatedQuestion)
  }

  const updateOption = (index: number, display: string) => {
    const newOptions = [...localQuestion.options]
    newOptions[index] = {
      ...newOptions[index],
      display,
    }
    const updatedQuestion = {
      ...localQuestion,
      options: newOptions,
    }
    setLocalQuestion(updatedQuestion) // Only update local state here
  }

  return (
    <FormLayout>
      <div className="space-y-4">
        <div>
          <Label>Question</Label>
          <input
            className="w-full p-2 border rounded"
            value={localQuestion.question}
            onChange={(e) =>
              setLocalQuestion({
                ...localQuestion,
                question: e.target.value,
              })
            }
            onBlur={() => onChange(localQuestion)}
          />
        </div>

        <div className="space-y-2">
          <Label>Options</Label>
          {localQuestion.options.map((option, index) => (
            <div key={option.key} className="flex items-center space-x-2">
              <input
                className="flex-1 p-2 border rounded"
                value={option.display}
                onChange={(e) => updateOption(index, e.target.value)}
                onBlur={() => onChange(localQuestion)}
              />
              <Button
                onClick={() => removeOption(index)}
                subtle
                variant="critical"
              >
                Remove
              </Button>
            </div>
          ))}
          <Button onClick={addOption} subtle>
            + Add Option
          </Button>
        </div>
      </div>
    </FormLayout>
  )
}

export function MultipleChoiceFormField() {
  const form = useFormikContext<any>()
  const [showQuestionModalIndex, setShowQuestionModalIndex] = useState<
    number | undefined
  >(undefined)

  return (
    <FieldArray
      name="multiChoice"
      render={(arrayHelpers) => (
        <div>
          <FormLayout dividers="lines">
            {form.values.multiChoice?.map(
              (question: MultiChoiceQuestion, i: number) => (
                <div key={question.key}>
                  {i > 0 && <FormLayout.Divider />}
                  <Inline alignX="between" width="full">
                    <div className="flex-wrap">
                      <Label>#{i + 1}</Label>{' '}
                      <span className="ml-5">{question.question}</span>
                    </div>

                    <Inline space="sm">
                      <Button
                        onClick={() => setShowQuestionModalIndex(i)}
                        subtle
                      >
                        Edit
                      </Button>

                      <Button
                        onClick={() => {
                          arrayHelpers.remove(i)
                        }}
                        subtle
                        variant="critical"
                      >
                        Remove
                      </Button>
                    </Inline>
                  </Inline>
                </div>
              ),
            )}
          </FormLayout>

          <Button
            fullWidth
            onClick={() => {
              const newQuestion: MultiChoiceQuestion = {
                key: uuidv4(),
                question: '',
                options: [
                  { display: '', key: uuidv4() },
                  { display: '', key: uuidv4() },
                ],
              }
              arrayHelpers.push(newQuestion)
              setShowQuestionModalIndex(form.values.multiChoice.length)
            }}
            subtle
          >
            + Add Question
          </Button>

          {showQuestionModalIndex !== undefined &&
            form.values.multiChoice[showQuestionModalIndex] && (
              <Modal
                closeHandler={() => setShowQuestionModalIndex(undefined)}
                escPressClose
                header={`Edit Question #${showQuestionModalIndex + 1}`}
              >
                <MultiChoiceQuestionEditor
                  initialValue={form.values.multiChoice[showQuestionModalIndex]}
                  onChange={(updatedQuestion) => {
                    arrayHelpers.replace(
                      showQuestionModalIndex,
                      updatedQuestion,
                    )
                  }}
                />
              </Modal>
            )}
        </div>
      )}
    />
  )
}
